@import "@/styles/base/typography";

.footer {
  height: 1.9rem;
  background-color: var(--color-neutral-03);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.row {
  display: flex;
  align-items: center;
  border: 0.1rem solid var(--color-neutral-05);
  min-height: 1.9rem;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.footerBtnContainer {
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-size-2);
  border-radius: 0 0 0.3rem 0.3rem;
  transition:
    background-color 0.2s ease,
    color 0.2s ease-in-out,
    opacity 0.2s ease;
  opacity: 1;
  border: 0.1rem solid var(--color-primary);
  border-top: 0;

  &:hover {
    background-color: var(--color-neutral-03);
    border: 0.1rem solid var(--color-neutral-05);
    border-top: 0;
    transition:
      background-color 0.2s ease,
      color 0.2s ease-in-out;

    .footerBtn {
      color: var(--color-neutral-01);
      background-color: var(--color-primary);
      transition:
        background-color 0.2s ease,
        color 0.2s ease-in-out;
    }
  }

  &.disable {
    &:hover {
      background-color: var(--color-primary);
      transition: none;
      border: 0.1rem solid var(--color-primary);
      border-top: 0;
    }

    .footerBtn {
      background-color: var(--color-tonal);
      transition: none;
      color: var(--color-primary);
    }
  }

  .row {
    border: 0;
  }
}

.footerBtn {
  padding: var(--space-size-1) var(--space-size-1-5);
  color: var(--color-primary);
  background-color: var(--color-tonal);
  @include heading-s-bold;
  border-radius: var(--space-size-1-5) 0.3rem;
  cursor: pointer;

  &.disable {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }
}
