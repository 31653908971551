@import "@/styles/base/typography";

.popoverContent {
  padding: 1rem;
  border-radius: 0.2rem;
  max-width: 30rem;
  background: var(--color-neutral-01);
  box-shadow:
    0 0 0 0.1rem
      color-mix(
        in oklab,
        color(display-p3 0.004 0.004 0.204/0.059),
        color(display-p3 0.94 0.941 0.953) 25%
      ),
    0 var(--space-size-1-5) 6rem color(display-p3 0 0 0/0.15),
    0 var(--space-size-1-5) var(--space-size-4) -1.6rem color(display-p3 0.004
          0.039 0.2/0.122);

  p {
    @include body-xs;
    color: #201f1e;
  }
}
