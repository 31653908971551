@import "@/styles/base/typography";

.dialogContent {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #042e62 0%,
    var(--color-primary) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 2;
}

.textBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  color: var(--color-neutral-01);
  @include heading-xl-bold;
}

.subtitle {
  color: var(--color-neutral-01);
  opacity: 0.6;
  margin: var(--space-size-3) 0;
  @include body;
}

.spinner {
  &::before {
    background-color: #082c5a;
  }
}

.actionContainer {
  background-color: var(--color-neutral-01);
  padding: 1rem;
  border-radius: 0.3rem;
  border: 0.2rem solid var(--color-neutral-05);
  margin: 4.5rem 0 0 0;
  @include body-s;
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  min-width: 46.5rem;
}

.button {
  @include body-s-bold;
  text-decoration: underline;
  margin: 0 var(--space-size-0-5);
  cursor: pointer;
}
