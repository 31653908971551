@import "@/styles/base/typography";
.container {
  color: var(--color-primary);
  padding: 0;
  margin: 0;
  @include heading-xxl-bold;
}

.marginTop {
  margin-top: var(--space-size-4);
}

.marginBottom {
  margin-bottom: 0.5rem;
}
