@import "@/styles/base/typography";

.overlay {
  background-color: #000000;
  height: calc(100% - 6rem);
  width: 0;
  position: fixed;
  top: 6rem;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &.open {
    width: 100%;
    opacity: 0.75;
    z-index: 2;
    transition: opacity 0.1s ease-in;
  }
}

.sidebar {
  position: fixed;
  top: 6rem;
  left: 0;
  height: calc(100% - 6rem);
  width: 38.5rem;
  padding-top: var(--space-size-4);
  background-color: var(--color-neutral-03);
  opacity: 1;
  z-index: 2;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;

  &.open {
    transform: translateX(0);
    transition: transform 0.3s ease-in;
  }
}

.subtitle {
  display: flex;
  align-items: center;
  color: var(--color-neutral-10);
  height: 3.1rem;
  padding-left: var(--space-size-4);
  text-transform: uppercase;
  border-bottom: 0.1rem solid var(--color-neutral-05);
  @include heading-xs-bold;
  margin: var(--space-size-1) 0 0 0;
}
