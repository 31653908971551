.binIcon {
  position: relative;
  margin: 0 auto;
  padding: var(--space-size-0-5);
  width: var(--space-size-3);
  height: var(--space-size-3);
  border-radius: var(--space-size-0-5);
  background-color: var(--color-error);
  cursor: pointer;
}
