.clipboardIcon {
  position: relative;
  display: inline-flex;
  margin-left: 1rem;
  padding: 0.6rem;
  width: var(--space-size-3);
  height: var(--space-size-3);
  border-radius: 50%;
  background-color: var(--color-border-01);
  cursor: pointer;
}
