@import "@/styles/base/typography";

.container {
  border: 0.1rem solid var(--color-neutral-05);
  display: flex;
  align-items: center;
  border-bottom: 0;

  &.highlighted {
    background-color: var(--color-neutral-03);
  }
}
