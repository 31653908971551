@import "@/styles/base/typography";

.btn {
  padding: var(--space-size-1) var(--space-size-1-5);
  @include heading-s-bold;
  border-radius: var(--space-size-1-5) 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  min-width: 8.5rem;
  z-index: 1;
  text-transform: capitalize;

  &::first-letter {
    text-transform: capitalize;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.35;
  }

  // NOTE size
  &.small {
    min-height: var(--space-size-4);
  }

  &.medium {
    min-height: var(--space-size-5);
  }

  // NOTE type
  &.primary {
    background-color: var(--color-tonal);
    color: var(--color-primary);
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: var(--btn-primary-hover);
      transition: background-color 0.2s ease-in;
    }

    &:active {
      background-color: var(--btn-primary-active);
      transition: background-color 0.2s ease-in;
    }
  }

  &.primaryDark {
    background-color: var(--color-primary);
    color: var(--color-neutral-01);
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: var(--btn-dark-hover);
      transition: background-color 0.2s ease-in;
    }

    &:active {
      background-color: var(--btn-dark-active);
      transition: background-color 0.2s ease-in;
    }
  }

  &.secondary {
    background-color: var(--color-neutral-01);
    color: var(--color-neutral-01);
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: var(--btn-secondary-hover);
      transition: background-color 0.2s ease-in;
    }

    &:active {
      background-color: var(--color-neutral-05);
      transition: background-color 0.2s ease-in;
    }
  }

  &.empty {
    background-color: transparent;
    border: none;
    color: var(--color-primary);
    @include body-s-bold;
    padding: 0.95rem var(--space-size-3);
  }

  &.link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: var(--color-secondary);
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    @include body-s-bold;
    min-height: unset;
  }
}

.icon {
  margin-right: var(--space-size-1);
  display: flex;
}
