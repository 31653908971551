@import "@/styles/base/typography";

.itemButton {
  display: flex;
  width: 100%;
  color: var(--color-primary);
  padding: var(--space-size-2) var(--space-size-4);
  @include heading-l-bold;

  &.isActive {
    background-color: #dfe7ee;
  }
}
