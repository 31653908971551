@import "@/styles/base/typography";

.overlay {
  background-color: #000000;
  opacity: 0.75;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--color-neutral-01);
  box-shadow: 0 var(--space-size-1-5) var(--space-size-3) 0 var(--color-shadow);
  min-width: 50.5rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.basicContent {
  border-radius: 0.3rem;
  padding: 2rem;

  .headerWrapper {
    margin: 0 0 2rem 0;
  }

  .title {
    @include heading-l-bold;
    color: var(--color-primary);
  }
}

.generalContent {
  border-radius: var(--border-radius);
  border: 0.2rem solid var(--color-neutral-05);
  padding: 0;

  .headerWrapper {
    background-color: var(--color-primary);
    padding: var(--space-size-2) var(--space-size-1-5);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    justify-content: center;
  }

  .title {
    @include body-s-bold;
    text-transform: uppercase;
    color: var(--color-neutral-01);
    width: calc(100% - 2.4rem);
    text-align: center;
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include heading-xs-bold;
  color: var(--color-neutral-01);
  text-transform: uppercase;
}

.closeBtn {
  width: var(--space-size-3);
  height: var(--space-size-3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
}

.cancelBtn {
  @include body-s-bold;
  color: var(--color-neutral-10);
  cursor: pointer;
  margin: 2rem 0 0 0;
  width: fit-content;
  text-transform: capitalize;
}
