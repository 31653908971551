@import "@/styles/base/typography";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem var(--space-size-2-5) 1rem var(--space-size-5);
  background-color: var(--color-primary);
  position: relative;
  min-height: 6rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.button {
  margin-right: var(--space-size-4);
}

.logoContainer {
  width: 10.1rem;
  aspect-ratio: 3.88;
  cursor: pointer;
}

.btnContainer {
  display: flex;
  align-items: center;
  position: relative;
}
