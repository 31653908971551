@import "@/styles/base/typography";

.tableHeader {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 2.7rem;
  @include body-xs-bold;
  border-radius: 0.3rem 0.3rem 0rem 0rem;
  color: var(--color-neutral-01);
  background: var(--color-primary);
}

.tableFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.1rem;
  background: var(--color-primary);
  border-radius: 0rem 0rem var(--border-radius) var(--border-radius);
}

.tableParagraph {
  width: 100%;
  padding: 1rem;
  background: var(--color-neutral-03);
  border-width: 0.1rem;
  border-color: var(--color-neutral-05);
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;

  p {
    @include body-s;
    color: #635452;
  }
}

.tableHeaderCell {
  @include body-xs-bold;
  text-align: left;
  color: var(--color-neutral-10);
  text-transform: uppercase;
  background: var(--color-neutral-03);
  padding: var(--space-size-0-5) 1rem;
  --table-row-box-shadow: none;
}

.tableHeaderRow {
  height: var(--space-size-3);
  vertical-align: middle;
  background: var(--color-neutral-03);
  border: 0.1rem solid var(--color-neutral-05);
  border-top: 0;
  border-bottom: 0;
}

.tableBodyCell {
  @include body-s;
  text-align: left;
  padding: 0 1rem;
  border: 0.1rem solid var(--color-neutral-05);
  --table-row-box-shadow: none;
}

.tableBodyRow {
  height: var(--space-size-6);
  vertical-align: middle;

  &.isAction {
    text-align: center;
  }
}
