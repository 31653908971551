@import "@/styles/base/typography";

.container {
  padding: 2rem var(--space-size-3);
  border: 0.2rem solid var(--color-neutral-05);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}

.paragraph {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-size-1);
  flex: 1 0 auto;
  margin: 0 var(--space-size-2) 0 0;
  max-width: calc(100% - 4rem);
}

.title {
  @include body-bold;
  color: var(--color-primary);
}

.text {
  @include body-s;
  color: var(--color-neutral-10);
}

.link {
  @include body-s;
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  text-align: left;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-size-0-5);
}

.percentage {
  @include body-s;
  color: var(--color-primary);
}

.iconContainer {
  background-color: var(--color-success);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--space-size-3);
  height: var(--space-size-3);
}
