.container {
  min-height: calc(100vh - 19.6rem);
  display: flex;
  position: relative;

  &.gradientBg {
    background-image: linear-gradient(
      180deg,
      var(--color-neutral-03) 0%,
      var(--color-neutral-03) 50%,
      rgba(238, 239, 241, 1) 50%,
      rgba(238, 239, 241, 1) 100%
    );
  }

  &.dFlex {
    align-items: center;
  }
}

.wrapper {
  max-width: 120.4rem;
  width: 100%;
  margin: 0 auto;
}
