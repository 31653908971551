@import "@/styles/base/typography";

.head {
  padding: 0.5rem var(--space-size-1-5);
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 0.3rem 0.3rem 0 0;
}

.row {
  display: flex;
  align-items: center;
}

.imageContainer {
  width: 1.8rem;
  aspect-ratio: 1;
}

.title {
  color: var(--color-neutral-01);
  padding-left: var(--space-size-0-5);
  @include body-xs-bold;
}
