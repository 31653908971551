@import "@/styles/base/typography";

.container {
  background-color: var(--color-neutral-01);
  padding: var(--space-size-0-5) var(--space-size-1-5) var(--space-size-0-5)
    2rem;
  border-radius: 5.2rem;
  @include body-s-bold;
  color: var(--color-primary);
  display: flex;
  align-items: center;
}

.iconContainer {
  margin: 0 var(--space-size-0-5) 0 0;
  display: flex;
}

.spinner {
  margin: 0 0 0 var(--space-size-1);
}
