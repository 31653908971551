@import "@/styles/base/typography";

.container {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: var(--space-size-7);
  right: 5rem;
  border: 0.2rem solid var(--color-neutral-05);
  border-radius: var(--border-radius);
  background-color: var(--color-neutral-01);
  box-shadow: 0 var(--space-size-1-5) var(--space-size-3) 0 var(--color-shadow);
  min-width: 54rem;
  z-index: 3;
  max-height: 60vh;
  overflow: hidden;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.title {
  background-color: var(--color-primary);
  padding: var(--space-size-1-5);
  @include body-s-bold;
  color: var(--color-neutral-01);
  text-align: center;
  text-transform: uppercase;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.contentWrapper {
  padding: 2rem;
  max-height: calc(60vh - 4.15rem);
  overflow: scroll;
}

.text {
  @include body-s;
  color: var(--color-neutral-10);
}

.list {
  margin: var(--space-size-1) 0 0 0;
  display: flex;
  flex-direction: column;
  row-gap: var(--space-size-1);
}
