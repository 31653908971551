@import "@/styles/base/typography";

.container {
  position: relative;
}

.userBtn {
  padding: var(--space-size-1);
  cursor: pointer;
  margin: 0 0 0 var(--space-size-2);
}

.iconContainer {
  height: var(--space-size-3);
  width: var(--space-size-3);
}

// TODO remove
.name {
  margin: var(--space-size-0-5) 0 0 0;
  color: var(--color-neutral-01);
  position: absolute;
  bottom: -0.4rem;
  white-space: nowrap;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.logContainer {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: calc(100% + 1.6rem);
  right: 1rem;
  border: var(--space-size-0-5) solid var(--color-neutral-01);
  border-radius: 0.3rem;
  padding: 0.6rem;
  background-color: var(--color-neutral-01);
  box-shadow: 0 var(--space-size-1-5) var(--space-size-3) 0 var(--color-shadow);
  display: flex;
  flex-direction: column;
  row-gap: var(--space-size-0-5);

  &.open {
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }
}

.logBtn {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  text-align: center;
  @include body-bold;

  &:hover {
    color: var(--color-secondary);
    text-decoration: underline;
    background-color: var(--color-neutral-02);
    transition: background-color 0.2s ease-in;
  }
}

.logText {
  @include body-bold;
  white-space: nowrap;
}

.logIcon {
  width: 2rem;
  margin-left: 1rem;
  color: var(--color-neutral-10);
  display: flex;
  align-items: center;
}
