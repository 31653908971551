.container {
  background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
    300% 100%;
  animation: skeleton-loading 1.5s linear infinite;
  border-radius: var(--space-size-0-5);
}

@keyframes skeleton-loading {
  to {
    background-position: left;
  }
}
