@import "@/styles/base/typography";

.footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: var(--space-size-6);
  background-color: var(--color-neutral-01);
  padding: var(--space-size-5);
  @include heading-xs;
}

.footerLinks {
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: var(--color-neutral-10);

  ul {
    display: flex;
    align-items: center;

    li {
      color: var(--color-neutral-10);
      margin-right: var(--space-size-1);
      @include heading-xs-bold;
    }
  }
}

.text {
  margin-right: var(--space-size-1);
}

.logoContainer {
  width: 8rem;
  aspect-ratio: 1.46;
}

.link {
  color: var(--color-neutral-10);
}
