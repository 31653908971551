@import "@/styles/base/typography";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  background: linear-gradient(
    180deg,
    rgba(60, 180, 230, 0.1) 0%,
    rgba(60, 180, 230, 0.5) 37%,
    var(--color-tertiary) 100%
  );
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: var(--color-neutral-01);
    border-radius: 50%;
    top: 0.2rem;
    bottom: 0.2rem;
    right: 0.2rem;
    left: 0.2rem;
  }

  &.xsmall {
    width: 2rem;
    height: 2rem;

    &::before {
      width: var(--space-size-2);
      height: var(--space-size-2);
    }
  }

  &.small {
    width: var(--space-size-3);
    height: var(--space-size-3);

    &::before {
      width: 2rem;
      height: 2rem;
    }
  }

  &.large {
    width: 8.5rem;
    height: 8.5rem;

    &::before {
      width: 7rem;
      height: 7rem;
      top: 0.75rem;
      bottom: 0.75rem;
      right: 0.75rem;
      left: 0.75rem;
    }
  }
}

.percentage {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
  @include heading-l;
  color: var(--color-neutral-01);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationLarge {
  to {
    transform: rotate(1turn);
  }
}
