@import "@/styles/base/typography";

.container {
  background-color: #f3d8da;
  border: 0.2rem solid #f0c3c7;
  border-radius: 0.3rem;
  @include body;
  color: #692326;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-size-1-5) var(--space-size-2-5);
  opacity: 0;
  max-height: 0;
  display: none;
  transform: scaleY(0);
  transition:
    transform 0.3s ease-in,
    opacity 0.1s ease-in 0.3s,
    display 0.1s ease-in 0.1s;
  transform-origin: top;

  &.visible {
    opacity: 1;
    max-height: 100%;
    display: flex;
    transform: scaleY(1);
    transition:
      display 0.1s ease-in,
      opacity 0.1s ease-in,
      transform 0.3s ease-in 0.5s;
    transform-origin: top;
  }

  &.marginBottom {
    margin-bottom: var(--space-size-4);
  }
}

.errorTitleContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  height: var(--space-size-3);
  margin: 0 1rem 0 0;
  flex: 0 0 var(--space-size-3);
}

.strong {
  @include body-bold;
  margin: 0 var(--space-size-0-5) 0 0;
  flex: 0 0 auto;
}

.copyBtn {
  width: var(--space-size-2-5);
  flex: 0 0 var(--space-size-2-5);
}

.children {
  width: calc(100% - 16rem);
}
