@import "@/styles/base/typography";

.overlay {
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
}

.content {
  position: relative;
  min-width: 80rem;
  background: var(--color-neutral-01);
  padding: 3rem;
  border-radius: var(--space-size-0-5);
}

.headerContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.title {
  @include heading-l-bold;
  color: var(--color-primary);
  margin-bottom: var(--space-size-1);
}

.close {
  margin: 0 0 0 auto;
}

.imageContainer {
  margin: 0 var(--space-size-1);
}

.description {
  display: flex;
  align-items: center;
  height: 5rem;
  @include body;
  color: var(--color-neutral-10);
}
