@import "@/styles/base/typography";

.table {
  width: 100%;

  &.marginBottom {
    margin-bottom: var(--space-size-2-5);
  }
}

.borderBotom {
  border-bottom: 0.1rem solid var(--color-neutral-05);
}
